<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <ALoader :isLoading="api.isLoading"></ALoader>
    <PageAdd
      v-if="!api.isLoading"
      :data="data"
      :callbackSuccess="callbackSuccess"
    ></PageAdd>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/application";
//EOC
import PageAdd from "@/components/Moderator/Application/Institution/PageAdd";
import { mapState } from "vuex";
export default {
  components: {
    PageAdd,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),

  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[self]
    me: {
      id: null,
    },
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: {},
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.me.name
        ? this.me.name
        : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${
            this.$route.params.id
          }`,
      to: this.$_getRouteRead(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self
      ),
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Approve",
      to: {name: 'PageModeratorApplicationApprove'},
      exact: true,
    });
    //EOC

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/moderator/application/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    callbackSuccess(resp) {
      this.$router.push(
        this.$_getRouteRead(this.role, this.model.key, resp.id, resp)
      );
    },
  },
};
</script>